.image {
	width: 100%;
	height: 150px;
	object-fit: cover;
}

.noImage {
	width: 100%;
	height: 150px;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #aaa;
}