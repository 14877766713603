.card {
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.card:hover{
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
}

.card .text {
	padding: 0.9em;
	padding-top: 0.8em;
	width: 70%;
}

.card .id {
	color: #414042;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
}

.card .label {
	color: #5E5D5D;
  	font-size: 9px;
	font-weight: 100;
	font-style: normal;
	text-align: left;
	margin-top: 1.0em;
	letter-spacing: .1rem;

}

.card .value {
	color: #414042;
  	font-size: 13px;
	font-weight: 300;
	margin-top: 0.2em;
}

.card .datetime {
	color: #414042;
  	font-size: 13px;
	font-weight: 300;
	display: flex;
	justify-content: space-between;
}