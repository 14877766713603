.container {
  padding: 1em;
  overflow-y: auto;
  max-height: 100vh;
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: repeat(auto-fill, 290px);
  justify-content: center;
}

@media (max-width: 420px) {
  .container {
    grid-template-columns: repeat(auto-fill, 250px);
  }
}